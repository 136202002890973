
angular
.module('modlin', ['ui.bootstrap.datetimepicker','pascalprecht.translate'])
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.debugInfoEnabled(false);
    }])
    .config(['$translateProvider',function ($translateProvider) {

        $translateProvider
            .useSanitizeValueStrategy('sanitizeParameters')
            //.fallbackLanguage(['pl','en'])
            .translations('en', {
                PODSUMOWANIE: 'Summary:'
                ,   MIEJSCE_WYDANIA_I_ZWROTU: 'Place of reception and pick up'
                ,   MIEJSCE_WYDANIA: 'Place of issue'
                ,   MIEJSCE_ZWROTU:'Pick up place'
                ,   DATA_I_GODZINA_ODBIORU:'Reception date'
                ,   DATA_I_GODZINA_WYDANIA:'Reception date'

                ,   HEADER:'Book a car'
                ,   CZAS_TRWANIA_WYNAJMU:"Rental time"
                ,   MIEJSCE_ODBIORU:'Place of reception'
                ,   DATA_I_GODZINA_ZWROTU:'Pick up date'


                ,   LOTNISKO_MODLIN:"Warsaw, Modlin Airport"
                ,   LOTNISKO_CHOPIN:"Warsaw, Chopin Airport"
                ,   LOTNISKO_MLOCINY: "Warsaw, Młociny"
                ,   LOTNISKO_INNE:"Other"

                ,   MIEJSCE_MODLIN:"Warsaw, Lotnisko Modlin (free of charge)"
                ,   MIEJSCE_CHOPIN:"Warsaw, Lotnisko Chopin (79 zł)"
                ,   MIEJSCE_MLOCINY: "Warsaw, Młociny (59 zł)"
                ,   MIEJSCE_INNE:"Return at another point (400 zł)"

                ,   DAY:"day"
                ,   DAYS:"days"
                ,   ZWROT:"Pick up"
                ,   ODBIOR:"Reception"
                ,   TWOJ_SAMOCHOD:"Your car:"
                ,   STAWKA_ZA_DZIEN:"per day"
                ,   DANE_ADRESOWE:'Booking details - your address'
                ,   DODATKI:"Additions"
                ,   AUTO:"Choose a car"
                ,   GO:"Next"
                ,   SUBMIT:"Submit"

                ,   OD:'From:'
                ,   DO:'To:'

                ,   Tak:"Yes"
                ,   Nie:"No"

                ,   THX_WE_WILL_CONTACT_YOU:"Thank you for submiting the booking form." +
                " <br>We will contact you soon!"


                ,   DO_ZAPLATY:"Payment:"

                ,   UBEZPIECZENIE:"Insurance"

                ,   "Toyota Aygo - Klasa A - MDM":"Toyota Aygo - Class A - MDM"
                ,   "Toyota Yaris - Klasa B - EDMR":"Toyota Yaris - Class B - EDMR"
                ,   "Toyota Auris - Klasa C - CDMR":"Toyota Auris - Class C - CDMR"
                ,   "Toyota Corolla - Klasa C - CDMR":"Toyota Corolla - Class C - CDMR"
                ,   "Ford Focus Kombi - Klasa C+ - CWMD":"Ford Focus Kombi - Class C+ - CWMD"
                ,   "Renault Traffic - Klasa R - SVMR":"Renault Traffic - Class R - SVMR"


                ,   IMIE:"First name"
                ,   NAZWISKO:"Last name"
                ,   EMAIL:"E-mail address"
                ,   TELEFON:"Telephone"
                ,   NIP:"Tax identification number (NIP)"
                ,   POLE_OPCJONALNE:"optional"
                ,   FIRMA:"Company name"
                ,   LOT:"Flight number"
                ,   BACK:"Back"




                , "Warszawa, Lotnisko Chopin":"Warsaw, Chopin Airport"
                , "Warszawa, Lotnisko Modlin":"Warsaw, Modlin Airport"
                , "Inne":"Other"

                ,   FILL_ADDRESS_AND_BOOK:"Continue to complete address details and book this car"


                ,   NAZWA:"Name"
                ,   'Cena':"Price"
                ,   'dni':"day"
                ,   'Cena promocyjna':"Promotion price"
                ,   'ceny mogą ulec zmianie':"prices are subject to be changed"
                ,   'Cena regularna':"Regular price"

                ,   'Dodatkowe ubezpieczenie <small>20 zł/ doba</small>\n<small>Zniesienie udziału własnego w szkodzie</small>'
                    :"Additional insurance <small>20 zł/day</small><small>no charge in case of car accident</small>"

                ,   'Dodatkowe ubezpieczenie <small>30 zł/ doba</small>\n<small>Zniesienie udziału własnego w szkodzie</small>'
                    :"Additional insurance <small>30 zł/day</small><small>no charge in case of car accident</small>"

                ,   'Dodatkowe ubezpieczenie <small>45 zł/ doba</small>\n<small>Zniesienie udziału własnego w szkodzie</small>'
                    :"Additional insurance <small>45 zł/day</small><small>no charge in case of car accident</small>"

                ,   'Zniesienie udziału własnego w szkodzie: uszkodzenia karoserii, opony, felgi, szyby'
                    :"Lifting the deductible from the damage: damage to the car body, tires, rims, windows"

                ,   'Nawigacja <small>10 zł/doba</small>'
                    :'Navigation <small>10 zł/day</small>'

                ,   'Nosidełko 0-9kg (9 zł)'
                    :'Baby car carrier 0-9kg (9 zł)'

                ,   'Fotelik dla dziecka 9-36 kg (9 zł)'
                    :'Child seat 9-36kg (9 zł)'

                ,   'Podkładka dziecięca (5 zł)'
                    :'children\'s pad (5 zł)'

                ,   'Fotelik dziecięcy <small>rozwiń<br> by zobaczyć ceny</small>'
                    :'Car safety seat <small>expand<br> see the price</small>'

                ,   'Dodatkowy fotelik<small>rozwiń<br> by zobaczyć ceny</small>'
                    :'Additional safety seat <small>expand<br> see the price</small>'

                ,   'Czyszczenie pojazdu<small>30 zł jednorazowo</small>'
                    :'Clearing the vehicle<small>30 zł one time fee</small>'

                ,   'Dodatkowy kierowca<small>5 zł</small>'
                    :'Additional driver<small>5 zł</small>'

                ,   'Kontynuuj by uzupełniać dane adresowe'
                    :'Continue to complete the address details and book this car'

                ,   'DELIVERY_FEE'
                    :'Fee for delivery (60 zł) and collection (60 zł) of the vehicle'

            })
            .translations('pl', {
                PODSUMOWANIE: 'Podsumowanie:'
                ,   MIEJSCE_WYDANIA_I_ZWROTU: 'Miejsce wydania i zwrotu'
                ,   MIEJSCE_WYDANIA: 'Miejsce wydania'
                ,   MIEJSCE_ZWROTU:'Miejsce zwrotu auta'
                ,   DATA_I_GODZINA_ODBIORU:'Data i godzina odbioru'
                ,   DATA_I_GODZINA_WYDANIA:'Data i godzina wydania'

                ,   HEADER:'Zarezerwuj samochód'
                ,   CZAS_TRWANIA_WYNAJMU:"Czas trwania wynajmu"
                ,   MIEJSCE_ODBIORU:'Miejsce odbioru auta'
                ,   DATA_I_GODZINA_ZWROTU:'Data i godzina zwrotu'


                ,   LOTNISKO_MODLIN:"Warszawa, Lotnisko Modlin"
                ,   LOTNISKO_CHOPIN:"Warszawa, Lotnisko Chopin"
                ,   LOTNISKO_MLOCINY: "Warszawa, Młociny"
                ,   LOTNISKO_INNE:"Inne"

                ,   MIEJSCE_MODLIN:"Warszawa, Lotnisko Modlin (bez dopłaty)"
                ,   MIEJSCE_CHOPIN:"Warszawa, Lotnisko Chopin (79 zł)"
                ,   MIEJSCE_MLOCINY: "Warszawa, Młociny (59 zł)"
                ,   MIEJSCE_INNE:"Inne (400 zł)"

                ,   DAY:"dzień"
                ,   DAYS:"dni"
                ,   ZWROT:"Zwrot"
                ,   ODBIOR:"Odbiór"
                ,   TWOJ_SAMOCHOD:"Twój samochód:"
                ,   STAWKA_ZA_DZIEN:"stawka za dzień"
                ,   DANE_ADRESOWE:'Rezerwacja - dane adresowe'
                ,   DODATKI:"Dodatki"
                ,   AUTO:"Wybierz samochód"
                ,   GO:"Przejdź dalej"

                ,   OD:'Od:'
                ,   DO:'Do:'

                ,   Tak:"Tak"
                ,   Nie:"Nie"

                ,   THX_WE_WILL_CONTACT_YOU:"Dziękujemy za przesłanie formularza rezerwacji, <br>skontaktujemy się z Tobą wkrótce!"


                ,   DO_ZAPLATY:"Do zapłaty:"

                ,   UBEZPIECZENIE:"Ubezpieczenie"

                ,   "Toyota Aygo - Klasa A - MDM":"Toyota Aygo - Klasa A - MDM"
                ,   "Toyota Yaris - Klasa B - EDMR":"Toyota Yaris - Klasa B - EDMR"
                ,   "Toyota Auris - Klasa C - CDMR":"Toyota Auris - Klasa C - CDMR"
                ,   "Toyota Corolla - Klasa C - CDMR":"Toyota Corolla - Klasa C - CDMR"
                ,   "Ford Focus Kombi - Klasa C+ - CWMD":"Ford Focus Kombi - Klasa C+ - CWMD"
                ,   "Renault Traffic - Klasa R - SVMR":"Renault Traffic - Klasa R - SVMR"


                ,   IMIE:"Imię"
                ,   NAZWISKO:"Nazwisko"
                ,   EMAIL:"Adres e-mail"
                ,   TELEFON:"Telefon"
                ,   NIP:"Nip"
                ,   POLE_OPCJONALNE:"pole opcjonalne"
                ,   FIRMA:"Nazwa firmy"
                ,   LOT:"Numer lotu"
                ,   SUBMIT:"Wyślij zgłoszenie"
                ,   BACK:"Cofnij"

                ,   FILL_ADDRESS_AND_BOOK:"Kontynuuj by uzupełniać dane adresowe <br>i zarezerwować ten samochód"

                ,   NAZWA:"nazwa"
                ,   'Cena':"Cena"
                ,   'dni':"dni"

                ,   'DELIVERY_FEE'
                    :'Opłata za podstawienie (60zł) i odbiór pojazdu (60zł)'

            });



    }]);




