angular.module("modlin")
    .controller('autko',
        [
                "$scope", "Shared","$filter","$http", "$document","$timeout",
            function (
                $scope,Shared,$filter, $http,$document, $timeout
            ) {
            var controller = this;

            this.etap=0;

            this.kto={};
            this.data={};
            this.dodatki = {};
            this.jednorazowo = {};
            this.cena = 0;


            this.warning=false;

            this.get=function(str){
                return Shared.get(str,"str")
            };

            //console.log($scope);

            if(!$scope.out)
                $scope.out={};


            if(!$scope.data)
                $scope.data={};

            var translate=$filter('translate');

            this.miejsca={
                wydanie:[
                    {id: "modlin", nazwa: translate("MIEJSCE_MODLIN"),ile:0},
                    {id: "chopin",nazwa:translate("MIEJSCE_CHOPIN"),ile:79},
                    {id: "mlociny",nazwa:translate("MIEJSCE_MLOCINY"),ile:59},
                    {id: "other",nazwa:translate("MIEJSCE_INNE"),ile:400}
                ],
                zwrot:[
                    {id: "modlin", nazwa: translate("MIEJSCE_MODLIN"),ile:0},
                    {id: "chopin",nazwa:translate("MIEJSCE_CHOPIN"),ile:79},
                    {id: "mlociny",nazwa:translate("MIEJSCE_MLOCINY"),ile:59},
                    {id: "other",nazwa:translate("MIEJSCE_INNE"),ile:400}
                ]
            };

            console.log("autko","this",this);

            this._dodatki= {
                ubezpieczenie: {
                    dane: [{ile: '0', nazwa: 'Nie'}, {ile: '30', nazwa: 'Tak (30 zł)'}],
                    nazwa:
                    translate("Dodatkowe ubezpieczenie") +
                    " <small>30 zł/ " +
                        translate("doba") +
                    "</small>\n<small>" +
                        translate("Zniesienie udziału własnego w szkodzie: uszkodzenia karoserii, opony, felgi, szyby") +
                    "</small>"
                },
                nawigacja: {
                    dane: [{ile: '0', nazwa: translate('Nie')}, {ile: '10', nazwa: translate('Tak')+' (10 zł)'}],
                    nazwa: translate("Nawigacja <small>10 zł/doba</small>")
                },
                fotelik: {
                    dane: [
                        {ile: 0, nazwa: "Nie"},
                        {ile: '9', nazwa: translate('Nosidełko 0-9kg (9 zł)')},
                        {ile: '9', nazwa: translate('Fotelik dla dziecka 9-36 kg (9 zł)')},
                        {ile: '5', nazwa: translate('Podkładka dziecięca (5 zł)')}
                    ],
                    nazwa: translate('Fotelik dziecięcy <small>rozwiń<br> by zobaczyć ceny</small>')
                },
                fotelik_dodatkowy: {
                    dane: [
                        {ile: 0, nazwa: "Nie"},
                        {ile: '9', nazwa: translate('Nosidełko 0-9kg (9 zł)')},
                        {ile: '9', nazwa: translate('Fotelik dla dziecka 9-36 kg (9 zł)')},
                        {ile: '5', nazwa: translate('Podkładka dziecięca (5 zł)')}
                    ],
                    nazwa: translate('Dodatkowy fotelik<small>rozwiń<br> by zobaczyć ceny</small>')
                }
            };

            this._jednorazowo={
                czyszczenie:{
                    nazwa:translate("Czyszczenie pojazdu<small>30 zł jednorazowo</small>"),
                    dane:[
                        {ile:'0',nazwa:translate('Nie')},{ile:'30',nazwa:translate('Tak')+' (30 zł)'}
                    ]
                },
                kierowca:{
                    nazwa:translate("Dodatkowy kierowca<small>5 zł</small>"),
                    dane:[{ile:'0',nazwa:translate('Nie')},{ile:'5',nazwa:translate('Tak')+' (5 zł)'}]
                }
            };

            this.miejsceWydaniaZwrotu=function(){
                try{
                    return controller.jednorazowo.wydanie.nazwa == controller.jednorazowo.zwrot.nazwa;
                }catch(e){
                    return true;
                }
            };
            this.miejsceWydania=function(){
                try{
                    return controller.jednorazowo.wydanie.nazwa ;
                }catch(e){
                    return '';
                }
            };
            this.miejsceZwrotu=function(){
                try{
                    return controller.jednorazowo.zwrot.nazwa;
                }catch(e){
                    return '';
                }
            };

            this.set=function(a){
                //console.log(a);
                return a;
            };
            this.ceny=function(input){

                var tabelka =
                    "<div><table class=\"table table-bordered\">\n    <tr>\n        <th translate=\'NAZWA\'></th>\n        <td ng-repeat=\'(id,v) in C.data.stawki\'><span translate>Cena</span> {{id}} <span translate>dni</span></td>\n    </tr>\n    <tr style=\"color: #900\" class=\'promotions\' ng-if=\'C.data.hasPromotions\'>\n        <th translate>Cena promocyjna</th>\n        <td ng-repeat=\'(id,v) in C.data.stawki\'>{{v[0]}}</td>\n        <td>(<span translate>ceny mogą ulec zmianie</span>)</td>\n    </tr>\n    <tr>\n        <th translate>Cena regularna</th>\n        <td ng-repeat=\'(id,v) in C.data.stawki\'>{{v[1]}}</td>\n    </tr>\n</table>\n</div>"

                var hasPromotions=false;
                var stawki={};
                var stawkiObliczeniowe=[];


                angular.forEach(input.split(/\|/), function(v){
                    var s=v.trim().split(":");
                    if(s.length<2) {
                        console.log("ERROR: ",s,v);
                        return;
                    }
                    var key = s.splice(0,1).join('');
                    var details = s.join(":");

                    var ds=details.split(",");
                    if(ds.length>1) {
                        controller.data.hasPromotions = true;
                    }else{
                        ds[1]=ds[0];
                    }


                    stawki[key.replace(/Cena|dni/g,"").trim()]=ds;

                    stawkiObliczeniowe.push({
                        name: key,
                        od: (new String(key)).replace(/Cena|dni/g,"").trim().replace(/-?[0-9]+$/,'').replace('+','').trim(),
                        za:(new String(ds[0]).replace("zł",'').trim())
                    });
                });

                controller.data.stawki = stawki;
                controller.data.stawkiObliczeniowe = stawkiObliczeniowe;

                return tabelka;
            };


            moment.locale(Shared.lang);

            $scope.endDateBeforeRender = endDateBeforeRender;
            $scope.endDateOnSetTime = endDateOnSetTime;
            $scope.startDateBeforeRender = startDateBeforeRender;
            $scope.startDateOnSetTime = startDateOnSetTime;

            function startDateOnSetTime () {
                $scope.$broadcast('start-date-changed');
            }

            function endDateOnSetTime () {
                $scope.$broadcast('end-date-changed');
            }

            var today=moment(new Date());


            function startDateBeforeRender ($dates) {
                if (controller.do) {
                    var activeDate = controller.moment_do = moment(controller.do);



                    $dates.filter(function (date) {
                        return date.localDateValue() >= activeDate.valueOf() || date.localDateValue() < today;
                    }).forEach(function (date) {
                        date.selectable = false;
                    })

                }else{
                    $dates.filter(function (date) {
                        return date.localDateValue() < today;
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                }
            }

            function endDateBeforeRender ($view, $dates) {
                if (controller.od) {

                    controller.moment_od = moment(controller.od);

                    var activeDate = moment(controller.od).subtract(1, $view).add(1, 'minute');



                    $dates.filter(function (date) {
                        return date.localDateValue() <= activeDate.valueOf()  || date.localDateValue() < today;
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                }else{
                    $dates.filter(function (date) {
                        return date.localDateValue() < today;
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                }
            }

            this.dateRender=function(newDate,old,target) {
                controller[target] = moment(newDate).format("YYYY-MM-DD HH:mm:ss");
            };

            function stawka() {

                var r = 0;
                var stawki = controller.data.stawkiObliczeniowe;
                var czas = controller.czas;

                for (var i = 0; i < stawki.length; i++) {
                    var stawka = stawki[i];
                    var od = parseInt(stawka.od);
                    if (czas >= stawka.od)
                        r = parseInt(stawka.za);
                }

                controller.stawka = r;
                return r;

            }

            function dodatki() {
                var sum = 0;
                angular.forEach(controller.dodatki, function (v) {
                    sum += parseInt(v.ile)
                });
                return sum;
            }

            function jednorazowe() {
                var sum = 0;
                angular.forEach(controller.jednorazowo, function (v) {
                    sum += parseInt(v.ile)
                });

                return sum;
            }

            function czyszczenie(czas){
                if(czas == 1 || czas == 2){
                    return 120
                }else{
                    return 0
                }
            }

            this.policz = function () {

                if(! controller.moment_do || !controller.moment_od)
                    return;

                controller.czas =  Math.ceil(
                    controller.moment_do.diff(controller.moment_od,"minutes") / (60*24)
                );

                controller.koszt = (controller.czas) * (stawka() + dodatki()) + jednorazowe() + czyszczenie(controller.czas);

                if (isNaN(this.koszt))
                    this.koszt = 0;


            };

            this.watcher = function(){
                if(!Shared)
                    return;
                if(! Shared.input)
                    return;


                if(Shared.input.lotnisko_id){
                    angular.forEach(controller.miejsca.wydanie, function(lotnisko){
                        if(lotnisko.id == Shared.input.lotnisko_id){
                            controller.jednorazowo.wydanie = lotnisko;
                            return false;
                        }
                    })
                }
                if(Shared.input.zwrot_id){
                    angular.forEach(controller.miejsca.zwrot, function(lotnisko){
                        if(lotnisko.id == Shared.input.zwrot_id){
                            controller.jednorazowo.zwrot = lotnisko;
                            return false;
                        }
                    })
                }


                if(Shared.input.do){
                    controller.do = Shared.input.do;
                    controller.do_render = moment(Shared.input.do).format("YYYY-MM-DD HH:mm:ss")
                }

                if(Shared.input.od){
                    controller.od = Shared.input.od;
                    controller.od_render = moment(Shared.input.od).format("YYYY-MM-DD HH:mm:ss")
                }
            }

            $scope.$watch(Shared.input, this.watcher);

            this.setEtap=function(num,req){
                if(!req) {
                    controller.etap = etap;
                    controller.warning=false;
                    return false;
                }
                var ok=true;

                angular.forEach(req, function(value,key){
                    if(!value) {
                        ok = false;
                        controller.warning=true;
                    }
                });

                if(ok) {
                    controller.etap = num;
                    controller.warning=false;
                }

                return ok;
            };

            this.wyslij=function(){

                controller.windowtitle = $document.title;
                controller.nazwa = $scope.nazwa;

                var dane = angular.toJson(controller);



                //console.log(dane);

                $http({
                    method:'POST',
                    url:'/_modlincars/mail.php',
                    data: dane
                }).then(function(response){
                    //console.log(response);
                })

            }
            this.watcher()
                $timeout(function(){
                    controller.policz()
                }, 10)
        }
    ]);
