angular
    .module("modlin")
    .directive('modlinZaproszenie', [function () {
        return {
            restrict: 'AC',
            templateUrl: 'zaproszenie.html',
            scope: false,
            transclude: true,
            controller: 'zaproszenieCtrl as C',
            compile: function (tElement, tAttrs, transclude) {

                var pre = function (scope, element, attrs, controller) {
                    controller.autka = [];
                    var links = document.querySelectorAll(".lotniska ul>li>a ");
                    angular.forEach(links, function (a) {
                        var $a = angular.element(a);
                        var href = $a.attr('href');
                        var text = $a.text();
                        controller.autka.push({
                            text: text,
                            href: href
                        });

                    });
                    //console.log(controller.autka);

                };
                var post = function (scope, element, attrs, controller) {

                };

                return {
                    pre: pre,
                    post: post
                }
            }
        }
    }])
    .controller('zaproszenieCtrl', ['$scope', 'Shared','$filter', function ($scope, Shared,$filter) {
            var controller = this;

            moment.locale('pl');

            this.dateRender = function (newDate, old, target) {
                controller[target] = moment(newDate).format("YYYY-MM-DD HH:mm:ss");
            };

            $scope.endDateBeforeRender = endDateBeforeRender;
            $scope.endDateOnSetTime = endDateOnSetTime;
            $scope.startDateBeforeRender = startDateBeforeRender;
            $scope.startDateOnSetTime = startDateOnSetTime;

            function startDateOnSetTime() {
                $scope.$broadcast('start-date-changed');
            }

            function endDateOnSetTime() {
                $scope.$broadcast('end-date-changed');
            }

            function startDateBeforeRender($dates) {
                if (controller.do) {
                    var activeDate = controller.moment_do = moment(controller.do);

                    var today = new Date();

                    $dates.filter(function (date) {
                        return date.localDateValue() >= activeDate.valueOf() || date.localDateValue() < moment(today).valueOf()
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                } else {
                    $dates.filter(function (date) {
                        return date.localDateValue() < today
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                }
            }

            function endDateBeforeRender($view, $dates) {
                if (controller.od) {

                    controller.moment_od = moment(controller.od);

                    var activeDate = moment(controller.od).subtract(1, $view).add(1, 'minute');

                    var today = new Date();

                    $dates.filter(function (date) {
                        return date.localDateValue() <= activeDate.valueOf() || date.localDateValue() < moment(today).valueOf()
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                } else {
                    $dates.filter(function (date) {
                        return date < today
                    }).forEach(function (date) {
                        date.selectable = false;
                    })
                }
            }

            var translate = $filter("translate");
            this.lotniska = [
                {id: "modlin", nazwa: translate("LOTNISKO_MODLIN")},
                {id: "chopin", nazwa: translate("LOTNISKO_CHOPIN")},
                {id: "mlociny", nazwa: translate("LOTNISKO_MLOCINY")},
                {id: "inne", nazwa: translate("LOTNISKO_INNE")}
            ];


            controller.go = function ($event) {
                let error=false;
                controller.error_autko = false;
                controller.error_lotnisko = false;
                controller.error_lotniskozwrot = false;
                controller.error_do = false;
                controller.error_od = false;

                if(! controller.autko) {
                    controller.error_autko=true;
                    error = true;
                }

                if(! controller.do) {
                    controller.error_do=true;
                    error = true;
                }
                if(! controller.od) {
                    controller.error_od=true;error = true;

                }
                if(! controller.lotnisko) {
                    controller.error_lotnisko=true;
                    error = true;
                }
                if(! controller.lotniskozwrot) {
                    controller.error_lotniskozwrot=true;
                    error = true;
                }

                if(error){
                    $event.preventDefault();
                }
            }

            }
        ]
    );
