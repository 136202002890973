
angular.module("modlin")
    .factory('Shared', function(){
        var stuff = {

        };

        return stuff;
    })
    .directive('modlinBoot',['Shared',function(Shared){
        return {
            restrict:'A',
            controller: function(){},
            transclude: true,
            template:'',
            compile:function(tElement, tAttrs, transclude){



                var pre=function(scope,element,attrs,controller){


                };

                var post=function(scope,element,attrs,controller){

                    var input = angular.fromJson(atob(tAttrs.input));

                    Shared.input = input;


                };

                return {pre:pre,post:post};
            }
        }
    }])
    .directive('lang', ['$translate',function($translate){
        return {
            restrict:'A',
            scope:{
                lang:'@'
            },
            controller: ['$scope','$translate','Shared', function($scope, $translate,Shared){
                var lang = $scope.lang;

                if ( lang == "en-gb" || lang == "en-us")
                        lang='en';
                else if ( lang == "pl-pl" )
                    lang='pl';
                else{
                    lang = lang.replace(/\-*/,'');
                }

                Shared.lang = lang;
                $translate.use(lang);

            }]
        }
    }]);
