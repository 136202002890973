angular.module("modlin")
    .directive('car', ['$compile',function ($compile) {
        return {
            restrict: 'AE',
            scope: {
                data: '=car',
                car: '@'
            },
            templateUrl: 'car-template.html',
            transclude: true,
            controller: 'autko as C',
            compile: function (tElement, tAttrs, transclude) {


                var pre = function (scope, iElement, iAttrs, controller) {
                    var data = transclude(scope);
                    scope.out = {};
                    angular.forEach(data.html().split(/----+/), function (value) {
                        var key, values;
                        var s = value.trim().split(/:/);

                        if (s.length < 2)
                            return true;


                        values = scope[key = s.shift().trim().toLowerCase()] = s.join(':').trim();


                        if (values.match(/^JSON/)) {
                            values = angular.fromJSON(values.replace(/^JSON/, ''));
                        }

                        if (controller[key]) {
                            //scope.out[key] = $interpolate(controller[key](values))(scope)
                            scope.out[key] = $compile(controller[key](values))(scope);
                        }


                        if (scope.ubezpieczenie != undefined) {
                            //console.log("car", "controller", controller);

                            try {
                                controller._dodatki.ubezpieczenie.nazwa = controller._dodatki.ubezpieczenie.nazwa.replace("25", parseInt(scope.ubezpieczenie));
                                controller._dodatki.ubezpieczenie.dane[1].ile = parseInt(scope.ubezpieczenie);//as string
                                // controller._dodatki.ubezpieczenie.dane[1].nazwa = translate("Tak")+" (" + (scope.ubezpieczenie) + ")";
                                controller._dodatki.ubezpieczenie.dane[1].nazwa = "Tak (" + (scope.ubezpieczenie) + ")";
                                console.log("ubezpieczenie alteration",controller._dodatki.ubezpieczenie);
                            } catch (e) {
                                console.log("parsing >>ubezpieczenie<<", e, controller._dodatki.ubezpieczenie);
                            }

                        }

                    });
                    // console.log(scope);
                };

                var post = function (scope, element, attrs, controller) {

                    var els = element.find('[data-insert]');
                    angular.forEach(els, function (el) {
                        var $el = angular.element(el);
                        var name = $el.attr('data-insert');
                        $el.append(scope.out[name] || "");
                    });


                };


                return {
                    pre: pre,
                    post: post
                }
            }
        }
    }])
